import { CssBaseline } from '@mui/material'
import { MenuAppBar, useAuthContext, useWtxLocalization, NotFoundPage } from '@wavetronix/common-components'
import LicensePage from './LicensePage'
import React from 'react'
import { env } from '../index.js'
import version from '../env/version.json'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

export default function MainPage() {
  const { user } = useAuthContext()
  const localizedStrings = useWtxLocalization()
  document.getElementById('title').innerHTML = localizedStrings.title

  return (
    <>
      <CssBaseline />
      <MenuAppBar user={user} env={env} appHeader='My Licenses' />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<LicensePage />} exact />
          <Route path='/version' element={<div>{version.version}</div>} exact />
          {/* This wildcard route needs to stay at the bottom */}
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}
